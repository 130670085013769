import { Checkbox, IconButton, ListItem, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';
import { white, pink} from '@mui/material/colors';


const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiCheckbox-colorSecondary": {
    color: '#FFFFFF',
  },
  "& .MuiCheckbox-colorPrimary": {
    color: '#FFFFFF',
  },
}));

const CustomClose = styled(CloseIcon)(({ theme }) => ({

  "& .MuiIconButton-root": {
    color: '#FFFFFF',
    padding: '0px',
    margin: '0px'
  },

}));

function Todo({ todo, toggleComplete, removeTodo }) {
  function handleCheckboxClick() {
    toggleComplete(todo.id);
  }

  function handleRemoveClick() {
    removeTodo(todo.id);
  }

  return (
    <ListItem style={{ display: "flex"}}>

      <div className="horizantal-Container" id="faraway">
        <div className="horizantal-Container">
          <Checkbox checked={todo.completed} onClick={handleCheckboxClick} style={{
                          color: "#FFFFFF",
                        }}/>

          <Typography
            variant="h3"
            style={{
              textDecoration: todo.completed ? "line-through" : null,
              color: '#FFFFFF',
              fontFamily: "Darker Grotesque",
              fontSize: "1.2em",
            }}
          >
            {todo.task}
          </Typography>
          </div>

        <IconButton onClick={handleRemoveClick}>
          <h3>x</h3>
        </IconButton>
        </div>
    </ListItem>
  );
}

export default Todo;
