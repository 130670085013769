export const authEndpoint = "https://accounts.spotify.com/authorize";

// Replace with your app's client ID, redirect URI and desired scopes
export const clientId = "f33bc511a1da4605b4cf7fdcb8d237f9";
export const redirectUri = "https://pui-final-inmyroom.vercel.app/";
export const scopes = [
    "user-top-read",
    "user-read-currently-playing",
    "user-read-playback-state",
];
