export default [
    {
        name: 'base',
        data: {},
        items:
        [
            { name: 'baked1_model', source: '/assets/models/bake1.glb' },
            { name: 'baked1_texture', source: '/assets/uv/bake1_night.png',type:'texture' },
            { name: 'baked1_texture_night', source: '/assets/uv/bake1_day.png',type:'texture' },

            { name: 'baked2_model', source: '/assets/models/bake2.glb' },
            { name: 'baked2_texture', source: '/assets/uv/bake2_night.png',type:'texture' },
            { name: 'baked2_texture_night', source: '/assets/uv/bake2_night.png',type:'texture' },

            { name: 'background_image', source: '/assets/city_images/1_background.png',type:'texture' },
            { name: 'foreground_image', source: '/assets/city_images/1_foreground.png',type:'texture' },
            { name: 'midground_image', source: '/assets/city_images/1_midground.png',type:'texture' },

            { name: 'cityscape_model', source: '/assets/models/cityscape.glb' },

        ]
    }
]
